// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCoreWorkoutsWorkoutTypes = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;

export const ModelsCoreWorkoutsWorkoutTypes = {
    StrengthTraining: 1 as ModelsCoreWorkoutsWorkoutTypes,
    FunctionalStrengthTraining: 2 as ModelsCoreWorkoutsWorkoutTypes,
    Recovery: 3 as ModelsCoreWorkoutsWorkoutTypes,
    Mobility: 4 as ModelsCoreWorkoutsWorkoutTypes,
    CoreTraining: 5 as ModelsCoreWorkoutsWorkoutTypes,
    Cardio: 6 as ModelsCoreWorkoutsWorkoutTypes,
    HIIT: 7 as ModelsCoreWorkoutsWorkoutTypes,
    Yoga: 8 as ModelsCoreWorkoutsWorkoutTypes,
    Pilates: 9 as ModelsCoreWorkoutsWorkoutTypes
};
