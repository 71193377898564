import { OnDestroy, Directive } from '@angular/core';
import { Subject, MonoTypeOperatorFunction } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Directive()
export abstract class BaseComponentDirective implements OnDestroy  {
    private unsubscribeSubject: Subject<void> = new Subject();

    ngOnDestroy() {
        this.onDestroy();
        this.unsubscribe();
    }

    protected onDestroy() {
    }

    protected unsubscribe() {
        this.unsubscribeSubject.next();
        this.unsubscribeSubject.complete();
    }

    protected takeUntilUnsubscribed<T>(): MonoTypeOperatorFunction<T> {
        return takeUntil(this.unsubscribeSubject);
    }
}
