import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TimeSpan } from '../../shared/util';
import { WorkoutsControllerProxy } from '../../shared/server-proxies/workouts-controller-proxy.service';
import { BaseComponentDirective as BaseComponent } from '../../shared/base-component.directive';
import { finalize } from 'rxjs/operators';

import {
    ModelsWebApiWorkoutsSaveWorkoutModel,
    ModelsCoreWorkoutsWorkout
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-workout-completion',
    templateUrl: './workout-completion.component.html'
})
export class WorkoutCompletionComponent extends BaseComponent implements OnInit {
    constructor(
        private router: Router,
        private currentRoute: ActivatedRoute,
        private workoutsControllerProxy: WorkoutsControllerProxy) {
        super();
    }

    workout: ModelsCoreWorkoutsWorkout;
    workoutElapsedTime: string;
    isSaving: boolean;

    ngOnInit() {
        this.currentRoute.params
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(
                params => {
                    const workoutId = params['workoutId'] || '';
                    this.workoutsControllerProxy.getWorkout(workoutId)
                        .pipe(this.takeUntilUnsubscribed())
                        .subscribe(
                            response => {
                                this.workout = response.body;
                                this.setWorkoutElapsedTime();
                            });
                });
    }

    setWorkoutElapsedTime() {
        const ts = TimeSpan.fromDateDifference(new Date(this.workout.created), new Date(this.workout.completed));
        this.workoutElapsedTime = `${ts.toString()}`;
    }

    save() {
        this.isSaving = true;
        return this.workoutsControllerProxy.saveWorkout(this.workout as ModelsWebApiWorkoutsSaveWorkoutModel)
            .pipe(
                finalize(
                    () => {
                        this.isSaving = false;
                    }),
                this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                    this.router.navigate(['/']);
                });
    }
}
