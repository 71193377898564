import { NgModule } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { InstrumentationService } from './instrumentation.service';
import { StorageService } from './storage.service';

@NgModule(
    {
        providers: [
            ConfigurationService,
            InstrumentationService,
            StorageService
        ]
    })
export class CoreModule {
}
