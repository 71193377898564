<div class="p-fluid">
    <div class="grid">
        <div class="col-12">
            <div class="card">
                <form #workoutForm="ngForm" autocomplete="off" novalidate="novalidate">
                    <p-panel id="exercisePanel" [hidden]="state?.isResting()" [header]="getExerciseHeader()" [toggleable]="true">
                        <p-tabView>
                            <p-tabPanel header="Details" leftIcon="fas fa-clipboard-list">
                                <table class="info-table">
                                    <thead>
                                    <tr>
                                        <th>Sets x {{repsName}}</th>
                                        <th *ngIf="state?.getCurrentWorkoutDetail().tempo">Tempo</th>
                                        <th *ngIf="state?.getCurrentWorkoutDetail().rest !== undefined">Rest</th>
                                        <th *ngIf="state?.getCurrentWorkoutDetail().exercise?.traineeExercise?.oneRepMax">1RM</th>
                                        <th *ngIf="state?.getCurrentWorkoutDetail().exercise?.traineeExercise?.personalRecord">PR</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>{{state?.getCurrentWorkoutDetail().prescription}}</td>
                                        <td *ngIf="state?.getCurrentWorkoutDetail().tempo">{{state?.getCurrentWorkoutDetail().tempo}}</td>
                                        <td *ngIf="state?.getCurrentWorkoutDetail().rest !== undefined">{{state?.getCurrentWorkoutDetail().rest}}</td>
                                        <td *ngIf="state?.getCurrentWorkoutDetail().exercise?.traineeExercise?.oneRepMax">{{state?.getCurrentWorkoutDetail().exercise?.traineeExercise?.oneRepMax}}</td>
                                        <td *ngIf="state?.getCurrentWorkoutDetail().exercise?.traineeExercise?.personalRecord">{{state?.getCurrentWorkoutDetail().exercise?.traineeExercise?.personalRecord}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </p-tabPanel>
                            <p-tabPanel header="Notes" leftIcon="fas fa-book-open">
                                <ul>
                                    <li *ngFor="let note of state?.getCurrentWorkoutDetail().notes">
                                        {{note}}
                                    </li>
                                    <li *ngIf="state?.getCurrentWorkoutDetail().exercise.videoUrl">
                                        <a target="_video" [href]="state?.getCurrentWorkoutDetail().exercise.videoUrl">
                                            <i class="fas fa-video"></i>
                                            Video Demonstration
                                        </a>
                                    </li>
                                </ul>
                            </p-tabPanel>
                            <p-tabPanel header="Previous" leftIcon="fas fa-history" *ngIf="state?.getPreviousSessionWorkoutDetail()">
                                <p *ngIf="!state?.getPreviousSessionWorkoutDetail().isExactMatch">{{state?.getPreviousSessionWorkoutDetail().displayText}}</p>
                                <i id="progressionIcon" *ngIf="state?.getPreviousSessionWorkoutDetail().earnedProgression" class="fas fa-check-circle" pTooltip="Increase Resistance this Workout"></i>
                                {{state?.getPreviousSessionWorkoutDetail().performance}}
                                on
                                {{state?.getPreviousSessionWorkoutDetail().date | date:'M/d/yyyy'}}
                            </p-tabPanel>
                            <p-tabPanel header="Plates" leftIcon="fas fa-dumbbell">
                                <my-plate-calculator [weight]="state?.getCurrentWorkoutLog().weight"></my-plate-calculator>
                            </p-tabPanel>
                        </p-tabView>
                    </p-panel>

                    <div [hidden]="state?.isResting()">
                        <div class="grid form-group">
                            <div *ngIf="isTrackingWeight" class="field col-12 md:col-4">
                                <span class="p-float-label">
                                    <input #weight="ngModel" class="center" type="number" pInputText min="0" step="5" pattern="\d+(\.\d{1,3})?" name="weight" [(ngModel)]="state?.getCurrentWorkoutLog().weight" (focus)="onFocusWeight()" required />
                                    <label>
                                        Weight
                                        <span class="error" *ngIf="weight.dirty && weight.hasError('required')">
                                            Required<i class="fas fa-exclamation"></i>
                                        </span>
                                        <span class="error" *ngIf="weight.dirty && weight.hasError('pattern')">
                                            Must be Numeric<i class="fas fa-exclamation"></i>
                                        </span>
                                    </label>
                                </span>
                            </div>
                            <div *ngIf="isTrackingReps" class="field col-12 md:col-4">
                                <span class="p-float-label">
                                    <input #reps="ngModel" class="center" type="number" pInputText min="0" step="1" pattern="\d*" name="reps" [(ngModel)]="state?.getCurrentWorkoutLog().reps" (focus)="onFocusReps()" required />
                                    <label>
                                        {{repsName}}
                                        <span class="error" *ngIf="reps.dirty && reps.hasError('required')">
                                            Required<i class="fas fa-exclamation"></i>
                                        </span>
                                        <span class="error" *ngIf="reps.dirty && reps.hasError('pattern')">
                                            Must be Numeric<i class="fas fa-exclamation"></i>
                                        </span>
                                    </label>
                                </span>
                            </div>
                            <div *ngIf="isTrackingRepsInReserve" class="field col-12 md:col-4">
                                <span class="p-float-label">
                                    <input #repsInReserve="ngModel" class="center" type="number" pInputText min="0" step="1" pattern="\d*" name="repsInReserve" [(ngModel)]="state?.getCurrentWorkoutLog().repsInReserve" (focus)="onFocusRepsInReserve()" required />
                                    <label>
                                        Reps in Reserve
                                        <span class="error" *ngIf="repsInReserve.dirty && repsInReserve.hasError('required')">
                                            Required<i class="fas fa-exclamation"></i>
                                        </span>
                                        <span class="error" *ngIf="repsInReserve.dirty && repsInReserve.hasError('pattern')">
                                            Must be Numeric<i class="fas fa-exclamation"></i>
                                        </span>
                                    </label>
                                </span>
                            </div>
                        </div>
                        <div class="grid">
                            <div class="col-12">
                                <p-progressBar *ngIf="state?.getWorkoutProgress() > 0" [value]="state?.getWorkoutProgress()" pTooltip="{{state?.getWorkoutProgressDescription()}}"></p-progressBar>
                            </div>
                            <div *ngIf="errorMessage" class="col-12">
                                <p-messages severity="error">
                                    <ng-template pTemplate>
                                        <div class="col-12 center">
                                            {{errorMessage}}
                                        </div>
                                    </ng-template>
                                </p-messages>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="shouldShowRestCounter()" class="grid">
                        <div class="col-12">
                            <p-messages severity="info">
                                <ng-template pTemplate>
                                    <div class="col-12 center" style="text-align: center">
                                        <h2>Remaining Rest:</h2>
                                        <p class="stop-watch">
                                            {{remainingRest | number:'1.1-1'}}
                                        </p>
                                        <p>
                                            <button type="button" class="width5" label="Skip" pButton icon="fas fa-arrow-right" iconPos="right" (click)="next()" [disabled]="isSaving"></button>
                                        </p>
                                        <h3>Up Next:</h3>
                                        <p>
                                            <small>{{getUpNextDescription()}}</small>
                                        </p>
                                    </div>
                                </ng-template>
                            </p-messages>
                        </div>
                    </div>
                    <div *ngIf="shouldShowRestComplete()" class="grid">
                        <div class="col-12">
                            <p-messages severity="info">
                                <ng-template pTemplate>
                                    <div class="col-12 center">
                                        <h2>Rest Complete!</h2>
                                        <p>
                                            <button type="button" label="Next" pButton icon="fas fa-arrow-right" iconPos="right" (click)="next()" [disabled]="isSaving"></button>
                                        </p>
                                    </div>
                                </ng-template>
                            </p-messages>
                        </div>
                    </div>

                    <div class="grid" *ngIf="!state?.isResting()">
                        <div class="col-4">
                            <button type="button" pButton label="Back" icon="fas fa-arrow-left" iconPos="left" (click)="previous()" *ngIf="!state?.isFirstSet()" [disabled]="isSaving || !workoutForm.form.valid"></button>
                        </div>
                        <div class="col-4 center">
                            <span *ngIf="shouldShowElapsedTime()">
                                Elapsed<br />
                                {{workoutElapsedTime}}
                            </span>
                        </div>
                        <div class="col-4">
                            <button type="submit" pButton label="Next" icon="fas fa-arrow-right" iconPos="right" (click)="next()" *ngIf="!state?.isLastSet()" [disabled]="isSaving || !workoutForm.form.valid"></button>
                            <button type="submit" pButton label="Next" icon="fas fa-arrow-right" iconPos="right" (click)="complete()" *ngIf="state?.isLastSet()" [disabled]="isSaving || !workoutForm.form.valid"></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>  
