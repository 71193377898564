export class QueryString {
    static fromObject(obj: any) {
        let queryString = '';

        if(obj) {
            const params: string[] = [];
            const keys = Object.keys(obj);

            for (let i = 0; i < keys.length; i++) {
                const key = keys[i];
                const value = obj[key];

                if(value) {
                    params.push(`${key}=${value}`);
                }
            }

            if(params.length > 0) {
                queryString = '?' + params.join('&');
            }
        }

        return queryString;
    }
}

export class TimeSpan {
    private static millisecondsPerSecond = 1000;
    private static millisecondsPerMinute = TimeSpan.millisecondsPerSecond * 60;
    private static millisecondsPerHour = TimeSpan.millisecondsPerMinute * 60;

    private myHours = 0;
    private myMinutes = 0;
    private mySeconds = 0;
    private myMilliseconds = 0;
    private myTotalHours = 0;
    private myTotalMinutes = 0;
    private myTotalSeconds = 0;
    private myTotalMilliseconds = 0;

    static fromDateDifference(from: Date | string, to: Date | string) {
        const ts = new TimeSpan();
        ts.subtractDates(from, to);
        return ts;
    }

    static fromMinutes(minutes: number) {
        const ts = new TimeSpan();
        ts.totalMinutes = minutes;
        return ts;
    }

    get hours() {
        return this.myHours;
    }

    get minutes() {
        return this.myMinutes;
    }

    get seconds() {
        return this.mySeconds;
    }

    get milliseconds() {
        return this.myMilliseconds;
    }

    get totalHours() {
        return this.myTotalHours;
    }

    set totalHours(value: number) {
        const milliseconds = value * TimeSpan.millisecondsPerHour;
        this.populateAllFieldsFromTotalMilliseconds(milliseconds);
    }

    get totalMinutes() {
        return this.myTotalMinutes;
    }

    set totalMinutes(value: number) {
        const milliseconds = value * TimeSpan.millisecondsPerMinute;
        this.populateAllFieldsFromTotalMilliseconds(milliseconds);
    }

    get totalSeconds() {
        return this.myTotalSeconds;
    }

    set totalSeconds(value: number) {
        const milliseconds = value * TimeSpan.millisecondsPerSecond;
        this.populateAllFieldsFromTotalMilliseconds(milliseconds);
    }

    get totalMilliseconds() {
        return this.myTotalMilliseconds;
    }

    set totalMilliseconds(value: number) {
        this.populateAllFieldsFromTotalMilliseconds(value);
    }

    subtractDates(from: Date | string, to: Date | string) {
        if(typeof from === 'string') {
            from = new Date(from.toString());
        }

        if(typeof to === 'string') {
            to = new Date(to.toString());
        }

        const milliseconds = (to.valueOf() as number - from.valueOf() as number);
        this.populateAllFieldsFromTotalMilliseconds(milliseconds);
    }

    toString(includeSeconds = false) {
        const segments: string[] = [];

        segments.push(this.myHours.toString());
        segments.push(this.numberToString(this.myMinutes));

        if(includeSeconds) {
            segments.push(this.numberToString(this.mySeconds));
        }

        return segments.join(':');
    }

    private populateAllFieldsFromTotalMilliseconds(totalMilliseconds: number) {
        this.myTotalMilliseconds = totalMilliseconds;
        this.myTotalSeconds = this.myTotalMilliseconds / TimeSpan.millisecondsPerSecond;
        this.myTotalMinutes = this.myTotalMilliseconds / TimeSpan.millisecondsPerMinute;
        this.myTotalHours = this.myTotalMilliseconds / TimeSpan.millisecondsPerHour;

        this.myMilliseconds = Math.floor(this.myTotalMilliseconds) % 1000;
        this.mySeconds = Math.floor(this.myTotalSeconds) % 60;
        this.myMinutes = Math.floor(this.myTotalMinutes) % 60;
        this.myHours = Math.floor(this.myTotalHours);
    }

    private numberToString(i: number, minLength = 2) {
        let s = i.toString();

        while(s.length < minLength) {
            s = '0' + s;
        }

        return s;
    }
}
