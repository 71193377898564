import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { AuthGuard } from '../shared/auth/auth-guard.service';
import { AppMainComponent } from '../app.main.component';
import { NewProgramComponent } from './new/new-program.component';
import { ProgramListComponent } from './list/program-list.component';
import { ProgramDetailComponent } from './detail/program-detail.component';

export const routes: Routes = [
    {
        path: 'programs',
        component: AppMainComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'new',
                component: NewProgramComponent
            },
            {
                path: ':programId',
                component: ProgramDetailComponent
            },
            {
                path: '',
                component: ProgramListComponent
            }
        ]
    }
];

export const programsRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
