import { NgModule } from '@angular/core';
import { HttpClientWrapperService } from './http-client-wrapper.service';
import { AuthenticationControllerProxy } from './authentication-controller-proxy.service';
import { ExercisesControllerProxy } from './exercises-controller-proxy.service';
import { ProgramsControllerProxy } from './programs-controller-proxy.service';
import { ReferenceControllerProxy } from './reference-controller-proxy.service';
import { UsersControllerProxy } from './users-controller-proxy.service';
import { WorkoutsControllerProxy } from './workouts-controller-proxy.service';

@NgModule(
    {
        providers: [
            HttpClientWrapperService,
            AuthenticationControllerProxy,
            ExercisesControllerProxy,
            ProgramsControllerProxy,
            ReferenceControllerProxy,
            UsersControllerProxy,
            WorkoutsControllerProxy
        ]
    })
export class ServerProxiesModule {
}
