import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { AuthGuard } from '../shared/auth/auth-guard.service';
import { AppMainComponent } from '../app.main.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';


export const routes: Routes = [
    {
        path: 'users',
        component: AppMainComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'dashboard',
                component: DashboardComponent
            },
            {
                path: 'profile',
                component: ProfileComponent
            }
        ]
    }
];

export const usersRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
