import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { usersRoutes } from './users.routes';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProfileComponent } from './profile/profile.component';

@NgModule({
    imports: [
        SharedModule,
        usersRoutes
    ],
    declarations: [
        DashboardComponent,
        ProfileComponent
    ],
    providers: [
    ]
})
export class UsersModule {

}
