import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { AuthGuard } from '../shared/auth/auth-guard.service';
import { AppMainComponent } from '../app.main.component';
import { WorkoutEntryComponent } from './entry/workout-entry.component';
import { WorkoutCompletionComponent } from './completion/workout-completion.component';

export const routes: Routes = [
    {
        path: 'workouts',
        component: AppMainComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: ':workoutId',
                component: WorkoutEntryComponent
            },
            {
                path: ':workoutId/complete',
                component: WorkoutCompletionComponent
            }
        ]
    }
];

export const workoutsRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
