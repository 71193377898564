import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

import { AuthGuard } from '../shared/auth/auth-guard.service';
import { AppMainComponent } from '../app.main.component';
import { ExerciseDetailComponent } from './exercises/detail/exercise-detail.component';

export const routes: Routes = [
    {
        path: 'exercises',
        component: AppMainComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: ':exerciseId',
                component: ExerciseDetailComponent
            }
        ]
    }
];

export const coreRoutes: ModuleWithProviders<RouterModule> = RouterModule.forChild(routes);
