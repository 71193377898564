// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCoreUsersEquipmentTypes = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export const ModelsCoreUsersEquipmentTypes = {
    Bar: 1 as ModelsCoreUsersEquipmentTypes,
    WeightPlate: 2 as ModelsCoreUsersEquipmentTypes,
    Kettlebell: 3 as ModelsCoreUsersEquipmentTypes,
    Band: 4 as ModelsCoreUsersEquipmentTypes,
    MiniBand: 5 as ModelsCoreUsersEquipmentTypes,
    LoopBand9: 6 as ModelsCoreUsersEquipmentTypes,
    LoopBand12: 7 as ModelsCoreUsersEquipmentTypes
};
