import { Injectable } from '@angular/core';
import { HttpClientWrapperService } from './http-client-wrapper.service';

import {
    ModelsCoreProgramsPeriodizationPhase,
    ModelsCoreSharedExerciseType,
    ModelsCoreSharedImplement,
    ModelsCoreSharedRepType,
    ModelsCoreWorkoutsWorkoutType
} from '../../shared/swagger-codegen/models';

@Injectable()
export class ReferenceControllerProxy {
    private baseUrl = '/api/reference';

    constructor(private httpClient: HttpClientWrapperService) {
    }

    getImplements() {
        return this.httpClient.get<ModelsCoreSharedImplement[]>(`${this.baseUrl}/implements`);
    }

    getRepTypes() {
        return this.httpClient.get<ModelsCoreSharedRepType[]>(`${this.baseUrl}/rep-types`);
    }

    getPeriodizationPhases() {
        return this.httpClient.get<ModelsCoreProgramsPeriodizationPhase[]>(`${this.baseUrl}/periodization-phases`);
    }

    getExerciseTypes() {
        return this.httpClient.get<ModelsCoreSharedExerciseType[]>(`${this.baseUrl}/exercise-types`);
    }

    getWorkoutTypes() {
        return this.httpClient.get<ModelsCoreWorkoutsWorkoutType[]>(`${this.baseUrl}/workout-types`);
    }
}
