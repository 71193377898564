<div class="p-fluid">
    <div class="grid">
        <div class="col-12">
            <div class="card card-w-title">
                <h1>Workout Completion</h1>
                <form  *ngIf="workout" #completionForm="ngForm" autocomplete="off" novalidate="novalidate">
                    <div>
                        <label>
                            Notes
                            <span class="error" *ngIf="notes.dirty && notes.hasError('maxlength')">
                                Must be Numeric<i class="fas fa-exclamation"></i>
                            </span>
                        </label>
                        <textarea #notes="ngModel" maxlength="750" pInputTextarea name="notes" rows="10" [(ngModel)]="workout.notes"></textarea>
                    </div>
                    <p class="center">
                        <button type="submit" pButton label="Complete" (click)="save()" [disabled]="isSaving || !completionForm.form.valid"></button>
                        <small>
                            Workout Completed in {{workoutElapsedTime}}
                        </small>
                    </p>
                </form>
            </div>
        </div>
    </div>
</div>
