import { Injectable } from '@angular/core';
import { QueryString } from '../util';
import { HttpClientWrapperService } from './http-client-wrapper.service';
import { map } from 'rxjs/operators';

import {
    ModelsWebApiWorkoutsGetWorkoutsArgs,
    ModelsWebApiWorkoutsSaveWorkoutLogModel,
    ModelsWebApiWorkoutsSaveWorkoutModel,
    ModelsWorkoutsCompleteWorkoutCompleteWorkoutResponse,
    ModelsWorkoutsSaveWorkoutLogSaveWorkoutLogResponse,
    ModelsCoreWorkoutsWorkout
} from '../../shared/swagger-codegen/models';

@Injectable()
export class WorkoutsControllerProxy {
    private baseUrl = '/api/workouts';

    constructor(private httpClient: HttpClientWrapperService) {
    }

    getWorkout(workoutId: number) {
        return this.httpClient.get<ModelsCoreWorkoutsWorkout>(`${this.baseUrl}/${workoutId}`);
    }

    getWorkouts(args?: ModelsWebApiWorkoutsGetWorkoutsArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsCoreWorkoutsWorkout[]>(`${this.baseUrl}${queryString}`);
    }

    saveWorkout(workout: ModelsWebApiWorkoutsSaveWorkoutModel) {
        return this.httpClient.put<ModelsCoreWorkoutsWorkout>(this.baseUrl, workout)
            .pipe(
                map(
                    response => {
                        workout.completed = response.body.completed;
                        return response;
                    }));
    }

    deleteWorkout(workoutId: number) {
        return this.httpClient.delete<any>(`${this.baseUrl}/${workoutId}`);
    }

    completeWorkout(workoutId: number) {
        return this.httpClient.put<ModelsWorkoutsCompleteWorkoutCompleteWorkoutResponse>(`${this.baseUrl}/${workoutId}/completion`);
    }

    saveWorkoutLog(workoutId: number, workoutLog: ModelsWebApiWorkoutsSaveWorkoutLogModel) {
        const url = `${this.baseUrl}/${workoutId}/workout-log`;
        return this.httpClient.post<ModelsWorkoutsSaveWorkoutLogSaveWorkoutLogResponse>(url, workoutLog);
    }
}
