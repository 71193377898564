import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';

import { SessionService } from './session.service';

@Injectable()
export class AuthService {
    constructor(private jwtHelper: JwtHelperService, private session: SessionService) {

    }

    isLoggedIn() {
        const token = this.session.getToken();
        return !this.jwtHelper.isTokenExpired(token);
    }
}
