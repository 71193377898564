import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ExercisesControllerProxy, ReferenceControllerProxy } from '../../../shared/server-proxies';
import { BaseComponentDirective as BaseComponent } from '../../../shared/base-component.directive';
import { SelectItem } from 'primeng/api';

import {
    ModelsCoreSharedExercise,
    ModelsCoreSharedExerciseTypes,
    ModelsWebApiExercisesSaveExerciseModel
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-exercise-detail',
    templateUrl: './exercise-detail.component.html'
})
export class ExerciseDetailComponent extends BaseComponent implements OnInit {
    constructor(
        private exercisesControllerProxy: ExercisesControllerProxy,
        private referenceControllerProxy: ReferenceControllerProxy,
        private currentRoute: ActivatedRoute) {
        super();
    }

    @Output()
    exerciseSaved = new EventEmitter<ModelsCoreSharedExercise>();

    @Input()
    exercise: ModelsCoreSharedExercise;
    exerciseTypes: SelectItem[] = [];
    isLoading = false;
    message: string;

    ngOnInit() {
        this.loadExerciseTypes();

        if(!this.exercise) {
            this.currentRoute.params
                .pipe(this.takeUntilUnsubscribed())
                .subscribe(
                    params => {
                        const exerciseId = parseInt(params['exerciseId'], 10) || 0;
                        this.loadExercise(exerciseId);
                    });
        }
    }
    
    saveExercise() {
        const model: ModelsWebApiExercisesSaveExerciseModel = {
            name: this.exercise.name,
            notes: this.exercise.notes,
            videoUrl: this.exercise.videoUrl,
            isWeighted: this.exercise.isWeighted,
            exerciseTypeId: this.exercise.exerciseTypeId as ModelsCoreSharedExerciseTypes
        };

        this.exercisesControllerProxy.saveExercise(this.exercise.exerciseId, model)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(response => {
                this.exercise.exerciseId = response.body.exerciseId;
                this.exerciseSaved.emit(this.exercise);
            });
    }

    private loadExerciseTypes() {
        this.referenceControllerProxy.getExerciseTypes()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(response => {
                this.exerciseTypes = response.body.map(t => {
                    return {
                        value: t.exerciseTypeId,
                        label: t.name
                    };
                });
            });
    }

    private loadExercise(exerciseId: number) {
        this.exercisesControllerProxy.getExercise(exerciseId)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(response => {
                this.exercise = response.body;
            });
    }
}
