<div class="card login-panel p-fluid">
    <form #profileForm="ngForm" autocomplete="off" novalidate="novalidate">
        <div class="grid">
            <div class="field col-12">                
                <span class="p-float-label">
                    <input #email="ngModel" type="email" name="email" pInputText [(ngModel)]="profile.email" required autofocus>
                    <label>
                        Email
                        <span class="error" *ngIf="email.dirty && email.hasError('required')">
                            Required<i class="fas fa-exclamation"></i>
                        </span>
                    </label>
                </span>
            </div>
            <div class="field col-12">                
                <span class="p-float-label">
                    <input #firstName="ngModel" type="text" name="firstName" pInputText [(ngModel)]="profile.firstName" required>
                    <label>
                        First Name
                        <span class="error" *ngIf="firstName.dirty && firstName.hasError('required')">
                            Required<i class="fas fa-exclamation"></i>
                        </span>
                    </label>
                </span>
            </div>
            <div class="field col-12">
                <span class="p-float-label">
                    <input #lastName="ngModel" type="text" name="lastName" pInputText [(ngModel)]="profile.lastName" required>
                    <label>
                        Last Name
                        <span class="error" *ngIf="lastName.dirty && lastName.hasError('required')">
                            Required<i class="fas fa-exclamation"></i>
                        </span>
                    </label>
                </span>
            </div>
            <div class="col-12">
                <button type="button" pButton label="Enable Fitbit Integration" (click)="enableOAuth('Fitbit')" [disabled]="isSaving  || !profileForm.form.valid"></button>
                <button type="button" pButton label="Enable Google Login" (click)="enableOAuth('Google')" [disabled]="isSaving  || !profileForm.form.valid"></button>
                <button type="button" pButton label="Enable Microsoft Login" (click)="enableOAuth('Microsoft')" [disabled]="isSaving  || !profileForm.form.valid"></button>
                <button type="button" pButton label="Enable Facebook Login" (click)="enableOAuth('Facebook')" [disabled]="isSaving  || !profileForm.form.valid"></button>
            </div>
            <div class="col-12">
                <button type="submit" pButton label="Save" icon="fas fa-user" (click)="save()" [disabled]="isSaving  || !profileForm.form.valid"></button>
                <p-messages [value]="messages" [closable]="false"></p-messages>
            </div>
        </div>
    </form>
</div>
