import { NgModule } from '@angular/core';
import { CoreModule } from '../core/core.module';
import { SharedModule } from '../shared/shared.module';
import { programsRoutes } from './programs.routes';
import { ProgramListComponent } from './list/program-list.component';
import { NewProgramComponent } from './new/new-program.component';
import { ProgramDetailComponent } from './detail/program-detail.component';
import { ProgramDetailProgramComponent } from './detail/program-detail-program.component';
import { ProgramDetailDayComponent } from './detail/program-detail-day.component';

@NgModule({
    imports: [
        CoreModule,
        SharedModule,
        programsRoutes
    ],
    declarations: [
        ProgramListComponent,
        NewProgramComponent,
        ProgramDetailComponent,
        ProgramDetailProgramComponent,
        ProgramDetailDayComponent
    ],
    providers: [
    ]
})
export class ProgramsModule {

}
