<div *ngIf="program" class="grid">
    <div class="p-fluid p-formgrid grid">
        <div class="field col-12">
            <label for="name">Name</label>
            <input id="name" [(ngModel)]="program.name" type="text" pInputText maxlength="100" required>
        </div>
        <div class="field col-12">
            <label for="description">Description</label>
            <textarea id="description" [(ngModel)]="program.description" type="text" rows="4" pInputTextarea></textarea>
        </div>
        <div class="field col-12 md:col-6">
            <label for="periodizationPhase">Periodization Phase</label>
            <p-dropdown inputId="periodizationPhase" [(ngModel)]="program.periodizationPhase.periodizationPhaseId" [options]="periodizationPhases"></p-dropdown>
        </div>
        <div class="field col-12 md:col-6">
            <label for="source">Source</label>
            <input id="source" [(ngModel)]="program.source" type="text" pInputText maxlength="500">
        </div>
        <div class="field col-12 md:col-6">
            <p-table [value]="programDaysWithRecords" (onRowReorder)="onRowReorder()" styleClass="with-bottom-margin">
                <ng-template pTemplate="caption">
                    <div class="center">
                        Days
                    </div>
                </ng-template>
                <ng-template pTemplate="body" let-record let-programDay="$implicit.programDay" let-rowIndex="rowIndex">
                    <tr [pReorderableRow]="rowIndex">
                        <td>
                            <i class="fa fa-bars clickable" pReorderableRowHandle></i>
                        </td>
                        <td>
                            {{programDay.description}}    
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="2">
                            No days.
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <button pButton type="button" label="Save Program Details" (click)="saveProgramDetails()"></button>
    </div>
</div>
