import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { BaseComponentDirective as BaseComponent } from '../../../shared/base-component.directive';

import {
    ModelsCoreSharedExercise
} from '../../../shared/swagger-codegen/models';

@Component({
    selector: 'my-exercise-detail-dialog',
    template: '<my-exercise-detail *ngIf="exercise" [exercise]="exercise" (exerciseSaved)="exerciseSaved()"></my-exercise-detail>'
})
export class ExerciseDetailDialogComponent extends BaseComponent implements OnInit {
    constructor(
        private dynamicDialogRef: DynamicDialogRef,
        private dynamicDialogConfig: DynamicDialogConfig) {

        super();
    }

    exercise: ModelsCoreSharedExercise;
    
    ngOnInit() {
        this.exercise = this.dynamicDialogConfig.data.exercise;
    }

    exerciseSaved() {
        this.dynamicDialogRef.close();
    }
}
