import { Injectable } from '@angular/core';

import {
    ModelsWebApiConfigurationModel
} from '../../shared/swagger-codegen/models';

/* eslint-disable no-var */
declare var window: any;

@Injectable()
export class ConfigurationService implements ModelsWebApiConfigurationModel {
    constructor() {
        const config: ModelsWebApiConfigurationModel = window.muscularityConfig;

        if(config) {
            Object.keys(config).forEach(key => {
                this[key] = config[key];
            });
        }
    }

    version: string;
    sdkVersion: string;
    buildDate: string;
    buildNumber: string;
    sourceBranch: string;
    sourceVersion: string;
    isDebug: boolean;
    environmentName: string;
    traceDsn: string;
    traceSampleRate: number;
}
