<div class="layout-topbar shadow-4">
    <div class="layout-topbar-left">
        <a class="layout-topbar-logo logo" routerLink="/">
            Muscularity
        </a>

        <a class="layout-menu-button shadow-6" (click)="appMain.onMenuButtonClick($event)" pRipple>
            <i class="pi pi-chevron-right"></i>
        </a>

        <!--<a class="layout-topbar-mobile-button" (click)="appMain.onTopbarMobileButtonClick($event)" pRipple>
            <i class="pi pi-ellipsis-v fs-large"></i>
        </a>-->
    </div>

    <div class="layout-topbar-right" [ngClass]="{'layout-topbar-mobile-active': appMain.mobileTopbarActive}">
        <div class="layout-topbar-actions-left">
            <!--<p-megaMenu [model]="model" styleClass="layout-megamenu"></p-megaMenu>-->
            <div class="non-production" *ngIf="isNonProduction">{{environmentName}}</div>
        </div>
        <!--
        <div class="layout-topbar-actions-right">
            <ul class="layout-topbar-items">
                <li class="layout-topbar-item layout-search-item">
                    <a class="layout-topbar-action rounded-circle" (click)="appMain.onTopbarItemClick($event, 'search')" pRipple>
                        <i class="pi pi-search fs-large"></i>
                    </a>

                    <div class="layout-search-panel p-inputgroup" [@topbarActionPanelAnimation]="'visible'" *ngIf="appMain.search" (@topbarActionPanelAnimation.done)="onSearchAnimationEnd($event)">
                        <span class="p-inputgroup-addon"><i class="pi pi-search"></i></span>
                        <input #searchInput type="text" pInputText placeholder="Search" (click)="appMain.searchClick = true;" (keydown)="appMain.onSearchKeydown($event)">
                        <span class="p-inputgroup-addon">
                            <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-text p-button-plain" (click)="appMain.searchClick = false;"></button>
                        </span>
                    </div>
                </li>
                <li class="layout-topbar-item notifications">
                    <a class="layout-topbar-action rounded-circle" (click)="appMain.onTopbarItemClick($event, 'notifications')" pRipple>
                        <span class="p-overlay-badge">
                            <i class="pi pi-bell fs-large"></i>
                            <span class="p-badge p-badge-warning badge-dot"></span>
                        </span>
                    </a>

                    <ul class="layout-topbar-action-panel shadow-6" [@topbarActionPanelAnimation]="'visible'" *ngIf="appMain.activeTopbarItem === 'notifications'">
                        <li class="mb-3">
                            <span class="px-3 fs-small">You have <b>4</b> new notifications</span>
                        </li>
                        <li class="layout-topbar-action-item">
                            <div class="flex flex-row align-items-center">
                                <img src="assets/demo/images/avatar/avatar-1.png"/>
                                <div class="flex flex-column" [ngClass]="{'ml-3': !app.isRTL, 'mr-3': app.isRTL}" style="flex-grow: 1;">
                                    <div class="flex align-items-center justify-content-between mb-1">
                                        <span class="fs-small font-bold">Jerome Bell</span>
                                        <small>42 mins ago</small>
                                    </div>
                                    <span class="fs-small">How to write content about your photographs?</span>
                                </div>
                            </div>
                        </li>
                        <li class="layout-topbar-action-item">
                            <div class="flex flex-row align-items-center">
                                <img src="assets/demo/images/avatar/avatar-2.png"/>
                                <div class="flex flex-column" [ngClass]="{'ml-3': !app.isRTL, 'mr-3': app.isRTL}" style="flex-grow: 1;">
                                    <div class="flex align-items-center justify-content-between mb-1">
                                        <span class="fs-small font-bold">Cameron Williamson</span>
                                        <small>48 mins ago</small>
                                    </div>
                                    <span class="fs-small">Start a blog to reach your creative peak.</span>
                                </div>
                            </div>
                        </li>
                        <li class="layout-topbar-action-item">
                            <div class="flex flex-row align-items-center">
                                <img src="assets/demo/images/avatar/avatar-3.png"/>
                                <div class="flex flex-column" [ngClass]="{'ml-3': !app.isRTL, 'mr-3': app.isRTL}" style="flex-grow: 1;">
                                    <div class="flex align-items-center justify-content-between mb-1">
                                        <span class="fs-small font-bold">Anna Miles</span>
                                        <small>1 day ago</small>
                                    </div>
                                    <span class="fs-small">Caring is the new marketing</span>
                                </div>
                            </div>
                        </li>
                        <li class="layout-topbar-action-item">
                            <div class="flex flex-row align-items-center">
                                <img src="assets/demo/images/avatar/avatar-4.png"/>
                                <div class="flex flex-column" [ngClass]="{'ml-3': !app.isRTL, 'mr-3': app.isRTL}" style="flex-grow: 1;">
                                    <div class="flex align-items-center justify-content-between mb-1">
                                        <span class="fs-small font-bold">Arlene Mccoy</span>
                                        <small>4 day ago</small>
                                    </div>
                                    <span class="fs-small">Starting your traveling blog with Vasco.</span>
                                </div>
                            </div>
                        </li>
                    </ul>
                </li>
                <li class="layout-topbar-item app">
                    <a class="layout-topbar-action rounded-circle" (click)="appMain.onTopbarItemClick($event, 'apps')" pRipple>
                        <i class="pi pi-table fs-large"></i>
                    </a>

                    <div class="layout-topbar-action-panel shadow-6" [@topbarActionPanelAnimation]="'visible'" *ngIf="appMain.activeTopbarItem === 'apps'">
                        <div class="grid grid-nogutter">
                            <div class="layout-topbar-action-item col-4">
                                <a class="flex align-items-center flex-column text-color" pRipple>
                                    <i class="pi pi-image action indigo-bgcolor white-color"></i>
                                    <span>Products</span>
                                </a>
                            </div>
                            <div class="layout-topbar-action-item col-4">
                                <a class="flex align-items-center flex-column text-color" pRipple>
                                    <i class="pi pi-file-pdf action orange-bgcolor white-color"></i>
                                    <span>Reports</span>
                                </a>
                            </div>
                            <div class="layout-topbar-action-item col-4">
                                <a class="flex align-items-center flex-column text-color" pRipple>
                                    <i class="pi pi-dollar action teal-bgcolor white-color"></i>
                                    <span>Balance</span>
                                </a>
                            </div>
                            <div class="layout-topbar-action-item col-4">
                                <a class="flex align-items-center flex-column text-color" pRipple>
                                    <i class="pi pi-cog action pink-bgcolor white-color"></i>
                                    <span>Settings</span>
                                </a>
                            </div>
                            <div class="layout-topbar-action-item col-4">
                                <a class="flex align-items-center flex-column text-color" pRipple>
                                    <i class="pi pi-key action bluegrey-bgcolor white-color"></i>
                                    <span>Credentials</span>
                                </a>
                            </div>
                            <div class="layout-topbar-action-item col-4">
                                <a class="flex align-items-center justify-content-center flex-column text-color" pRipple>
                                    <i class="pi pi-sitemap action cyan-bgcolor white-color"></i>
                                    <span>Sitemap</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </li>
                <li class="layout-topbar-item">
                    <a class="layout-topbar-action flex dir-row justify-content-center align-items-center px-2 rounded-circle" (click)="appMain.onTopbarItemClick($event, 'profile')" pRipple>
                        <img src="assets/demo/images/avatar/amyelsner.png" alt="avatar" style="width: 32px; height: 32px;">
                    </a>

                    <ul class="layout-topbar-action-panel shadow-6" [@topbarActionPanelAnimation]="'visible'" *ngIf="appMain.activeTopbarItem === 'profile'">
                        <li class="layout-topbar-action-item">
                            <a class="flex flex-row align-items-center" pRipple>
                                <i class="pi pi-cog" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                                <span>Settings</span>
                            </a>
                        </li>
                        <li class="layout-topbar-action-item">
                            <a class="flex flex-row align-items-center" pRipple>
                                <i class="pi pi-file-o" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                                <span>Terms of Usage</span>
                            </a>
                        </li>
                        <li class="layout-topbar-action-item">
                            <a class="flex flex-row align-items-center" pRipple>
                                <i class="pi pi-compass" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                                <span>Support</span>
                            </a>
                        </li>
                        <li class="layout-topbar-action-item">
                            <a class="flex flex-row align-items-center" pRipple>
                                <i class="pi pi-power-off" [ngClass]="{'mr-2': !app.isRTL, 'ml-2': app.isRTL}"></i>
                                <span>Logout</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="layout-topbar-item">
                    <a class="layout-topbar-action rounded-circle" (click)="appMain.onRightMenuButtonClick($event)" pRipple>
                        <i class="pi fs-large" [ngClass]="{'pi-arrow-left': !app.isRTL, 'pi-arrow-right': app.isRTL}"></i>
                    </a>
                </li>
            </ul>
        </div>
        -->
    </div>
</div>
