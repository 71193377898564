/* eslint-disable */
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { Router } from "@angular/router";
import * as Sentry from "@sentry/angular";

import { appRoutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppMainComponent } from './app.main.component';
import { AppConfigComponent } from './app.config.component';
import { AppMenuComponent } from './app.menu.component';
import { AppMenuitemComponent } from './app.menuitem.component';
import { AppInlineMenuComponent } from './app.inlinemenu.component';
import { AppRightMenuComponent } from './app.rightmenu.component';
import { AppBreadcrumbComponent } from './app.breadcrumb.component';
import { AppTopBarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';
import { SharedModule } from './shared/shared.module';
import { AuthenticationModule } from './authentication/authentication.module';
import { ProgramsModule } from './programs/programs.module';
import { UsersModule } from './users/users.module';
import { WorkoutsModule } from './workouts/workouts.module';

import { MenuService } from './app.menu.service';
import { AppBreadcrumbService } from './app.breadcrumb.service';

@NgModule({ declarations: [
        AppComponent,
        AppMainComponent,
        AppConfigComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppInlineMenuComponent,
        AppRightMenuComponent,
        AppBreadcrumbComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppBreadcrumbComponent
    ],
    bootstrap: [
        AppComponent
    ], imports: [appRoutes,
        BrowserModule,
        CommonModule,
        FormsModule,
        BrowserAnimationsModule,
        SharedModule.forRoot(),
        AuthenticationModule,
        ProgramsModule,
        UsersModule,
        WorkoutsModule], providers: [
        AppBreadcrumbService,
        MenuService,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {

}
