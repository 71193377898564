<div *ngIf="program" class="grid">
    <div class="col-12">
        <h1>{{program.name}}</h1>
        <a *ngIf="!isShowingChildComponent" class="clickable" (click)="showingDetails = true">
            <i class="far fa-newspaper"></i>
            Program Details
        </a>
        <a *ngIf="isShowingChildComponent" class="clickable" (click)="showParentComponent()">
            <i class="fas fa-arrow-left"></i>
            Return to Program Exercises
        </a>
    </div>
    <my-program-detail-program *ngIf="showingDetails" [program]="program" [programDaysWithRecords]="programDaysWithRecords" (save)="saveProgramDetails($event)"></my-program-detail-program>
    <my-program-detail-day *ngIf="editingProgramDay" [day]="editingProgramDay" (save)="saveProgramDay($event)"></my-program-detail-day>
    <div *ngIf="!isShowingChildComponent" class="col-12">
        <p-tabView [(activeIndex)]="selectedTabIndex" (onChange)="onTabChanged($event)">
            <p-tabPanel *ngFor="let dayRecord of programDaysWithRecords; let tabIndex = index;">
                <ng-template pTemplate="header">
                    <span>{{dayRecord.programDay.description}}</span>
                    <i class="pi pi-cog clickable edit-icon" style="margin-left: 10px; z-index: 9999;" pTooltip="Edit Day" (click)="onEditProgramDay(dayRecord)"></i>
                    <i *ngIf="tabIndex === programDaysWithRecords.length - 1" class="fas fa-plus clickable add-icon" style="margin-left: 10px; z-index: 9999;" pTooltip="Add Day" (click)="onAddProgramDay()"></i>
                </ng-template>
                <div *ngFor="let sessionRecord of dayRecord.records">
                    <h3 *ngIf="sessionRecord.programSession.description">{{sessionRecord.programSession.description}}</h3>
                    <p-table [value]="sessionRecord.records" [loading]="isLoading" [editingRowKeys]="editingRowKeys" dataKey="id" editMode="row" (onRowReorder)="onRowReorder(sessionRecord, $event)" styleClass="with-bottom-margin">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="reorder-column"></th>
                                <th class="width3 center">Set</th>
                                <th>Exercise</th>
                                <th *ngIf="sessionRecord.isMultiWeekProgram">Week</th>
                                <th class="width5 center">Prescription</th>
                                <th class="width3 center">Rep Type</th>
                                <th class="width3 center">Tempo</th>
                                <th class="width3 center">Rest</th>
                                <th class="width3"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-record let-programLineItem="$implicit.programLineItem" let-programExercise="$implicit.programExercise" let-programWeek="$implicit.programWeek" let-rowIndex="rowIndex" let-editing="editing">
                            <tr [pEditableRow]="record" [pReorderableRow]="rowIndex">
                                <td>
                                    <i class="fa fa-bars clickable" pReorderableRowHandle></i>
                                </td>
                                <td class="center" *ngIf="record.programWeekIndexWithinProgramLineItem === 0" [attr.rowspan]="record.programWeeksPerProgramLineItem">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div *ngIf="superSets.length > 1" class="p-fluid p-formgrid grid">
                                                <div class="field col-12">
                                                    <label>Set</label>
                                                    <p-dropdown inputId="superSetDisplayText" [options]="superSets" [(ngModel)]="rowEditModel.superSetDisplayText" appendTo="body"></p-dropdown>
                                                </div>
                                            </div>
                                            <span *ngIf="superSets.length === 1">{{programLineItem.superSetDisplayText}}</span>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{programLineItem.superSetDisplayText}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td *ngIf="record.programWeekIndexWithinProgramExercise === 0" [attr.rowspan]="record.programWeeksPerProgramExercise">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div class="p-fluid p-formgrid grid">
                                                <div class="field col-2">
                                                    <label for="implement">Implement</label>
                                                    <p-dropdown inputId="implement" [options]="implements" [(ngModel)]="rowEditModel.implementId" (onChange)="onImplementChanged()" appendTo="body"></p-dropdown>
                                                </div>
                                                <div class="field col-5">
                                                    <label for="exercise">Exercise</label>
                                                    <p-autoComplete inputId="exercise" required [(ngModel)]="rowEditModel.selectedExercise" [suggestions]="exerciseSearchResults" (completeMethod)="searchExercises($event)" [placeholder]="programExercise.exercise.name" field="name" dataKey="exerciseId" emptyMessage="No matching exercises found." minLength="3" (onSelect)="exerciseSelected()" appendTo="body"></p-autoComplete>
                                                </div>
                                                <div class="field col-5" *ngIf="variations.length > 0">
                                                    <label for="variations">Variations</label>
                                                    <p-multiSelect inputId="variations" [options]="variations" [(ngModel)]="rowEditModel.selectedVariationIds" display="chip" appendTo="body"></p-multiSelect>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <a class="clickable" (click)="editExercise(programExercise.exercise)">{{programExercise.displayText}}</a>
                                            <a class="clickable" style="margin-left: 5px" *ngIf="programExercise.exercise.videoUrl" [href]="programExercise.exercise.videoUrl" target="_exercise-video">
                                                <i class="fas fa-file-video" pTooltip="Play Exercise Video"></i>
                                            </a>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td class="center" *ngIf="sessionRecord.isMultiWeekProgram">
                                    <span *ngIf="record.programWeeksPerProgramExercise > 1">{{programWeek.ordinal + 1}}</span>
                                </td>
                                <td class="center">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div class="p-fluid p-formgrid grid">
                                                <div class="field col-12">
                                                    <label>Prescription</label>
                                                    <input class="center" pInputText type="text" [(ngModel)]="rowEditModel.prescription" maxlength="50">
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{programWeek?.prescription?.displayText}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td class="center">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div class="p-fluid p-formgrid grid">
                                                <div class="field col-12">
                                                    <label>Rep Type</label>
                                                    <p-dropdown [options]="repTypes" [(ngModel)]="rowEditModel.repTypeId" appendTo="body"></p-dropdown>
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{programWeek?.repType?.name}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td class="center">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div class="p-fluid p-formgrid grid">
                                                <div class="field col-12">
                                                    <label>Tempo</label>
                                                    <input class="center" pInputText type="text" [(ngModel)]="rowEditModel.tempo" maxlength="15">
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{programWeek?.tempo}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td class="center">
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <div class="p-fluid p-formgrid grid">
                                                <div class="field col-12">
                                                    <label>Rest</label>
                                                    <input class="center" pInputText min="0" type="number" [(ngModel)]="rowEditModel.rest">
                                                </div>
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{programWeek?.rest}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td class="center">
                                    <span class="status-icons">
                                        <i *ngIf="!editing && !rowEditModel" pRipple type="button" pInitEditableRow class="fas fa-edit clickable edit-icon" pTooltip="Edit" (click)="onRowEditInit(sessionRecord, record, rowIndex)"></i>
                                        <i *ngIf="!editing && !rowEditModel" pRipple type="button" class="fas fa-trash-alt clickable delete-icon" pTooltip="Delete" (click)="deleteRow(sessionRecord, record, rowIndex)"></i>
                                        <i *ngIf="editing" pRipple type="button" pSaveEditableRow class="fas fa-save clickable save-icon" pTooltip="Save" (click)="onRowEditSave(sessionRecord, record, rowIndex)"></i>
                                        <i *ngIf="editing" pRipple type="button" pCancelEditableRow class="fas fa-ban clickable cancel-icon" pTooltip="Cancel" (click)="onRowEditCancel(sessionRecord)"></i>
                                    </span>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td [attr.colspan]="sessionRecord.isMultiWeekProgram ? 6 : 5">
                                    No exercises.
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <button *ngIf="!rowEditModel" type="button" pButton label="Add Line Item" icon="fas fa-plus" (click)="addProgramLineItem(sessionRecord)" [disabled]="isLoading"></button>
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>
<p-confirmDialog key="program-detail-component" icon="pi pi-exclamation-triangle"></p-confirmDialog>
