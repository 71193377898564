<form #plateCalcForm="ngForm" class="grid">
    <div class="field col-12 md:col-4">
        <span class="p-float-label">
            <input #calcWeight="ngModel" type="number" pInputText min="0" step="5" pattern="\d+(\.\d{1,3})?" name="weight" [(ngModel)]="weight" required />
            <label>
                Weight
                <span class="error" *ngIf="calcWeight.dirty && calcWeight.hasError('required')">                                            
                    Required<i class="fas fa-exclamation"></i>
                </span>
                <span class="error" *ngIf="calcWeight.dirty && calcWeight.hasError('pattern')">
                    Must be Numeric<i class="fas fa-exclamation"></i>
                </span>
            </label>
        </span>
    </div>
    <div class="field col-12 md:col-4">
        <span class="p-float-label">
            <p-dropdown name="bar" [options]="barItems" [(ngModel)]="selectedBar" (onChange)="barChanged()"></p-dropdown>
            <label>Plates/Side:</label>
        </span>
        <ul>
            <li *ngFor="let equipment of getRequiredEquipment()">
                {{ equipment }}
            </li>
        </ul>
    </div>
</form>
