<div class="pages-body login-page flex flex-column">
    <div class="topbar p-3 flex justify-content-between flex-row align-items-center">
        <div class="topbar-left ml-3 flex" routerLink="/">
            <div class="logo">
                Muscularity
            </div>
        </div>
        <div class="topbar-right mr-3 flex">

        </div>
    </div>

    <div class="align-self-center mt-auto mb-auto">
        <div class="pages-panel card flex flex-column">
            <div class="pages-header px-3 py-1">
                <h2>LOGIN</h2>
            </div>

            <h4>Welcome to Muscularity</h4>
            <div *ngIf="isLoggingInWithToken" class="pages-detail mb-6 px-6">
                Logging In<br />
                <i class="fas fa-spinner fa-pulse"></i>
            </div>

            <div class="input-panel flex flex-column px-3">
                <form #loginForm="ngForm" autocomplete="off" novalidate="novalidate">
                    <div *ngIf="!isLoggingInWithToken">
                        <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-envelope"></i>
                            </span>
                            <span class="p-float-label">
                                <input #email="ngModel" type="email" name="email" pInputText [(ngModel)]="credentials.email" autocomplete="username" required [attr.autofocus]="getEmailAutoFocus()">
                                <label>
                                    Email
                                    <span class="error" *ngIf="email.dirty && email.hasError('required')">
                                        Required<i class="fas fa-exclamation"></i>
                                    </span>
                                </label>
                            </span>
                        </div>

                        <div class="p-inputgroup mt-3 mb-3">
                            <span class="p-inputgroup-addon">
                                <i class="pi pi-lock"></i>
                            </span>
                            <span class="p-float-label">
                                <input #password="ngModel" type="password" name="password" pInputText [(ngModel)]="credentials.password" autocomplete="current-password" required [attr.autofocus]="getPasswordAutoFocus()">
                                <label>
                                    Password
                                    <span class="error" *ngIf="password.dirty && password.hasError('required')">
                                        Required<i class="fas fa-exclamation"></i>
                                    </span>
                                </label>
                            </span>
                        </div>

                        <div class="p-inputgroup mt-4 mb-3">
                            <p-checkbox name="rememberMe" label="Remember Me" [(ngModel)]="credentials.rememberMe" binary="true"></p-checkbox>
                        </div>
                    </div>
                </form>
            </div>

            <p-button *ngIf="!isLoggingInWithToken" class="login-button mt-3 mb-1 px-3" label="LOGIN" icon="fas fa-user" (click)="login()" [disabled]="isLoading  || !loginForm.form.valid"></p-button>
            <p-messages *ngIf="!isLoggingInWithToken" [value]="messages" [closable]="false"></p-messages>
        </div>
    </div>
</div>
