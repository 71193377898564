import { Injectable } from '@angular/core';
import { StorageService } from '../core/storage.service';

import {
    ModelsCoreUsersUserAccount
} from '../../shared/swagger-codegen/models';

@Injectable()
export class SessionService {
    private tokenKey = 'token';
    private userKey = 'user';

    constructor(private storageService: StorageService) {

    }

    getToken(): string {
        return this.storageService.getSessionItem(this.tokenKey);
    }

    getPersistedToken(): string {
        return this.storageService.getItem(this.tokenKey);
    }

    getUser(): ModelsCoreUsersUserAccount {
        const json = this.storageService.getSessionItem(this.userKey);
        return json ? JSON.parse(json) : null;
    }

    initializeSession(user: ModelsCoreUsersUserAccount, token: string, rememberMe: boolean) {
        this.removeObsoleteStoredValues();

        if(rememberMe) {
            this.storageService.setItem(this.tokenKey, token);
        }

        this.storageService.setSessionItem(this.tokenKey, token);
        this.setUser(user);
    }

    disposeSession() {
        this.storageService.deleteSessionItem(this.tokenKey);
        this.storageService.deleteItem(this.tokenKey);

        this.storageService.deleteSessionItem(this.userKey);
        this.storageService.deleteItem(this.userKey);
    }

    private setUser(user: ModelsCoreUsersUserAccount) {
        const json = JSON.stringify(user);
        this.storageService.setSessionItem(this.userKey, json);
    }

    private removeObsoleteStoredValues() {
        const obsoleteKeys = [
            'email',
            'token',
            'user'
        ];

        obsoleteKeys.forEach(obsoleteKey => this.storageService.deleteItem(obsoleteKey, false));
    }
}
