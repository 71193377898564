import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { coreRoutes } from './core.routes';

import { ExerciseDetailComponent } from './exercises/detail/exercise-detail.component';
import { ExerciseDetailDialogComponent } from './exercises/detail/exercise-detail-dialog.component';
import { ExerciseDetailDialogService } from './exercises/detail/exercise-detail-dialog.service';

@NgModule({
    imports: [
        SharedModule,
        coreRoutes
    ],
    declarations: [
        ExerciseDetailComponent,
        ExerciseDetailDialogComponent
    ],
    providers: [
        ExerciseDetailDialogService
    ]
})
export class CoreModule {

}
