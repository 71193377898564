// This content was generated from '/api/utility/swagger-extensions'

export type ModelsCoreSharedExerciseTypes = 1 | 2 | 3 | 4;

export const ModelsCoreSharedExerciseTypes = {
    Strength: 1 as ModelsCoreSharedExerciseTypes,
    Cardio: 2 as ModelsCoreSharedExerciseTypes,
    Mobility: 3 as ModelsCoreSharedExerciseTypes,
    CardioIntervals: 4 as ModelsCoreSharedExerciseTypes
};
