import { Injectable } from '@angular/core';
import { HttpClientWrapperService } from './http-client-wrapper.service';
import { QueryString } from '../util';

import {
    ModelsCoreSharedExercise,
    ModelsCoreSharedVariation,
    ModelsExercisesSaveExerciseSaveExerciseResponse,
    ModelsWebApiExercisesSaveExerciseModel,
    ModelsWebApiExercisesSearchArgs,
    ModelsWebApiExercisesVariationsArgs
} from '../../shared/swagger-codegen/models';

@Injectable()
export class ExercisesControllerProxy {
    private baseUrl = '/api/exercises';

    constructor(private httpClient: HttpClientWrapperService) {
    }

    getExercise(exerciseId: number) {
        return this.httpClient.get<ModelsCoreSharedExercise>(`${this.baseUrl}/${exerciseId}`);
    }

    saveExercise(exerciseId: number, model: ModelsWebApiExercisesSaveExerciseModel) {
        return this.httpClient.post<ModelsExercisesSaveExerciseSaveExerciseResponse>(`${this.baseUrl}/${exerciseId}`, model);
    }

    searchExercises(args?: ModelsWebApiExercisesSearchArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsCoreSharedExercise[]>(`${this.baseUrl}/search${queryString}`);
    }

    getExerciseVariations(exerciseId: number, args?: ModelsWebApiExercisesVariationsArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsCoreSharedVariation[]>(`${this.baseUrl}/${exerciseId}/variations${queryString}`);
    }
}
