import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ProgramsControllerProxy } from '../../shared/server-proxies/programs-controller-proxy.service';
import { BaseComponentDirective as BaseComponent } from '../../shared/base-component.directive';
import { finalize } from 'rxjs/operators';

import {
    ModelsCoreProgramsProgram,
    ModelsWebApiProgramsGetProgramsArgs,
    ModelsWebApiProgramsStartNextWorkoutArgs
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-program-list',
    templateUrl: './program-list.component.html'
})
export class ProgramListComponent extends BaseComponent implements OnInit {
    constructor(
        private programsControllerProxy: ProgramsControllerProxy,
        private router: Router,
        private confirmationService: ConfirmationService) {
        super();
    }

    programs: ModelsCoreProgramsProgram[] = [];
    isLoading = false;
    message: string;

    ngOnInit() {
        this.loadPrograms();
    }

    copyProgram(program: ModelsCoreProgramsProgram) {
        this.isLoading = true;
        this.programsControllerProxy.copyProgram(program.programId)
            .pipe(
                finalize(
                    () => {
                        this.isLoading = false;
                    }),
                this.takeUntilUnsubscribed())
            .subscribe(() => {
                this.loadPrograms();
            });
    }

    completeProgram(program: ModelsCoreProgramsProgram) {
        const message = `Are you sure you want to mark the ${program.name} program as complete?`;

        this.confirmationService.confirm(
            {
                key: 'program-list-component',
                header: 'Complete Program?',
                message: message,
                accept: () => {
                    this.isLoading = true;
                    this.programsControllerProxy.completeProgram(program.programId)
                        .pipe(
                            finalize(
                                () => {
                                    this.isLoading = false;
                                }),
                            this.takeUntilUnsubscribed())
                        .subscribe(() => {
                            this.loadPrograms();
                        });
                }
            });
    }

    startWorkout(program: ModelsCoreProgramsProgram, programSessionId?: number) {
        this.isLoading = true;

        const args: ModelsWebApiProgramsStartNextWorkoutArgs = {
            programSessionId: programSessionId
        };

        this.programsControllerProxy.startNextWorkout(program.programId, args)
            .pipe(
                finalize(
                    () => {
                        this.isLoading = false;
                    }),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.router.navigate(['/workouts', response.body.workoutId]);
                });
    }

    getNewWorkoutMenuItems(program: ModelsCoreProgramsProgram) {
        const items: MenuItem[] = [];

        program.programSessionSpecifiers.forEach(s => {
            items.push({
                label: s.description,
                command: () => this.startWorkout(program, s.programSessionId)
            });
        });

        return items;
    }

    private loadPrograms() {
        const args: ModelsWebApiProgramsGetProgramsArgs = {
            page: 0,
            pageSize: 75,
            includeCompleted: true
        };

        this.isLoading = true;
        this.programsControllerProxy.getPrograms(args)
            .pipe(
                finalize(
                    () => {
                        this.isLoading = false;
                    }),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.programs = response.body;
                });
    }
}
