import { Injectable } from '@angular/core';
import { HttpClientWrapperService } from './http-client-wrapper.service';

import {
    ModelsCoreUsersTraineeEquipment,
    ModelsCoreUsersUserAccount,
    ModelsWebApiUsersSaveProfileModel
} from '../../shared/swagger-codegen/models';

@Injectable()
export class UsersControllerProxy {
    private baseUrl = '/api/users';

    constructor(private httpClient: HttpClientWrapperService) {
    }

    getEquipment() {
        return this.httpClient.get<ModelsCoreUsersTraineeEquipment[]>(`${this.baseUrl}/equipment`);
    }

    getProfile() {
        return this.httpClient.get<ModelsCoreUsersUserAccount>(`${this.baseUrl}/profile`);
    }

    saveProfile(userAccount: ModelsWebApiUsersSaveProfileModel) {
        return this.httpClient.put<any>(`${this.baseUrl}/profile`, userAccount);
    }
}
