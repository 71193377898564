import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
    constructor() {
        this.localStorage = window.localStorage;
        this.sessionStorage = window.sessionStorage;
        this.localCache = {};
        this.sessionCache = {};
    }

    private localCache: any;
    private sessionCache: any;
    private localStorage: Storage;
    private sessionStorage: Storage;

    getItem(key: string, prefixKeyWithAppNamespace = true) {
        return this.get(this.localStorage, this.localCache, key, prefixKeyWithAppNamespace);
    }

    setItem(key: string, data: any, prefixKeyWithAppNamespace = true) {
        this.set(this.localStorage, this.localCache, key, data, prefixKeyWithAppNamespace);
    }

    deleteItem(key: string, prefixKeyWithAppNamespace = true) {
        this.set(this.localStorage, this.localCache, key, null, prefixKeyWithAppNamespace);
    }

    getSessionItem(key: string, prefixKeyWithAppNamespace = true) {
        return this.get(this.sessionStorage, this.sessionCache, key, prefixKeyWithAppNamespace);
    }

    setSessionItem(key: string, data: any, prefixKeyWithAppNamespace = true) {
        this.set(this.sessionStorage, this.sessionCache, key, data, prefixKeyWithAppNamespace);
    }

    deleteSessionItem(key: string, prefixKeyWithAppNamespace = true) {
        this.set(this.sessionStorage, this.sessionCache, key, null, prefixKeyWithAppNamespace);
    }

    clear() {
        this.localStorage.clear();
        this.localCache = {};

        this.sessionStorage.clear();
        this.sessionCache = {};
    }

    private get(storage: Storage, cache: any, key: string, prefixKeyWithAppNamespace = true) {
        let value = cache[key];
        const storageKey = prefixKeyWithAppNamespace ? `muscularity_${key}` : key;

        if(!value) {
            value = JSON.parse(storage.getItem(storageKey));

            if(value) {
                cache[key] = value;
            }
        }

        return value;
    }

    private set(storage: Storage, cache: any, key: string, data: any, prefixKeyWithAppNamespace = true) {
        const storageKey = prefixKeyWithAppNamespace ? `muscularity_${key}` : key;

        if(data) {
            storage.setItem(storageKey, JSON.stringify(data));
            cache[key] = data;
        }
        else {
            storage.removeItem(storageKey);
            delete cache[key];
        }
    }
}
