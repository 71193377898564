<div class="grid">
    <div class="col-12">
        <div class="card card-w-title">
            <h1>Programs</h1>
            <p-table [value]="programs" [paginator]="programs && programs.length > 30" [pageLinks]="10" [rows]="30" [loading]="isLoading">
                <ng-template pTemplate="header">
                    <tr>
                        <th>Name</th>
                        <th class="width15">Source</th>
                        <th class="width5 center">Periodization</th>
                        <th class="width2 center">Created</th>
                        <th class="width2 center">First<br />Workout</th>
                        <th class="width2 center">Last<br />Workout</th>
                        <th class="width2 center">Workouts<br />Completed</th>
                        <th class="width2 center">Completed</th>
                        <th class="width10"></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-program let-rowIndex="rowIndex">
                    <tr>
                        <td>
                            <span class="p-column-title">Name</span>
                            <a routerLink="/programs/{{program.programId}}">{{program.name}}</a>
                        </td>
                        <td>
                            <span class="p-column-title">Source</span>
                            {{program.source}}
                        </td>
                        <td class="center">
                            <span class="p-column-title">Periodization</span>
                            {{program.periodizationPhase?.name}}
                        </td>
                        <td class="center">
                            <span class="p-column-title">Created</span>
                            {{program.created | date:'shortDate'}}
                        </td>
                        <td class="center">
                            <span class="p-column-title">First Workout</span>
                            {{program.firstWorkoutCompleted | date:'shortDate'}}
                        </td>
                        <td class="center">
                            <span class="p-column-title">Last Workout</span>
                            {{program.lastWorkoutCompleted | date:'shortDate'}}
                        </td>
                        <td class="center">
                            <span class="p-column-title">Workouts Completed</span>
                            {{program.workoutsCompleted | number}}
                        </td>
                        <td class="center">
                            <span class="p-column-title">Completed</span>
                            {{program.completed | date:'shortDate'}}
                        </td>
                        <td class="center">
                            <p>
                                <p-splitButton *ngIf="!program.completed"  label="New Workout" icon="far fa-play-circle" (onClick)="startWorkout(program)" [model]="getNewWorkoutMenuItems(program)" [disabled]="isLoading"></p-splitButton>
                            </p>
                            <span class="status-icons">
                                <i class="fas fa-clone clickable copy-icon" pTooltip="Create a Copy" (click)="copyProgram(program)"></i>
                                <i *ngIf="!program.completed" class="fas fa-check clickable complete-icon" pTooltip="Complete Program" (click)="completeProgram(program)"></i>
                            </span>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="3">
                            No active programs.
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>
<p-confirmDialog key="program-list-component" icon="pi pi-exclamation-triangle"></p-confirmDialog>
