import { Injectable } from '@angular/core';
import { HttpClientWrapperService } from './http-client-wrapper.service';
import { QueryString } from '../util';

import {
    ModelsCoreProgramsPeriodizationPhase,
    ModelsCoreProgramsProgram,
    ModelsProgramsCopyProgramCopyProgramResponse,
    ModelsProgramsCreateProgramCreateProgramResponse,
    ModelsProgramsSaveProgramWeekSaveProgramWeekResponse,
    ModelsWebApiProgramsCreateProgramModel,
    ModelsWebApiProgramsGetProgramsArgs,
    ModelsWebApiProgramsSaveProgramModel,
    ModelsWebApiProgramsSaveProgramWeekProgramWeekModel,
    ModelsWebApiProgramsStartNextWorkoutArgs,
    ModelsWorkoutsStartNextWorkoutStartNextWorkoutResponse
} from '../../shared/swagger-codegen/models';

@Injectable()
export class ProgramsControllerProxy {
    private baseUrl = '/api/programs';

    constructor(private httpClient: HttpClientWrapperService) {
    }

    getPrograms(args?: ModelsWebApiProgramsGetProgramsArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.get<ModelsCoreProgramsProgram[]>(`${this.baseUrl}${queryString}`);
    }

    getProgram(programId: number) {
        return this.httpClient.get<ModelsCoreProgramsProgram>(`${this.baseUrl}/${programId}`);
    }

    getPeriodizationPhases() {
        return this.httpClient.get<ModelsCoreProgramsPeriodizationPhase[]>(
            `${this.baseUrl}/periodization-phases`);
    }

    createProgram(program: ModelsWebApiProgramsCreateProgramModel) {
        return this.httpClient.post<ModelsProgramsCreateProgramCreateProgramResponse>(`${this.baseUrl}`, program);
    }

    saveProgram(programId: number, model: ModelsWebApiProgramsSaveProgramModel) {
        return this.httpClient.put<ModelsProgramsCreateProgramCreateProgramResponse>(`${this.baseUrl}/${programId}`, model);
    }

    copyProgram(programId: number) {
        return this.httpClient.post<ModelsProgramsCopyProgramCopyProgramResponse>(`${this.baseUrl}/${programId}/copy`);
    }

    completeProgram(programId: number) {
        return this.httpClient.put<any>(`${this.baseUrl}/${programId}/complete`);
    }

    saveProgramWeek(programId: number, model: ModelsWebApiProgramsSaveProgramWeekProgramWeekModel) {
        return this.httpClient.post<ModelsProgramsSaveProgramWeekSaveProgramWeekResponse>(`${this.baseUrl}/${programId}/weeks`, model);
    }

    deleteProgramWeek(programId: number, programWeekId: number) {
        return this.httpClient.delete<any>(`${this.baseUrl}/${programId}/weeks/${programWeekId}`);
    }

    startNextWorkout(programId: number, args?: ModelsWebApiProgramsStartNextWorkoutArgs) {
        const queryString = QueryString.fromObject(args);
        return this.httpClient.post<ModelsWorkoutsStartNextWorkoutStartNextWorkoutResponse>(
            `${this.baseUrl}/${programId}/workout${queryString}`);
    }
}
