<div class="card card-w-title p-fluid">
    <h1>New Program</h1>
    <form #programForm="ngForm" autocomplete="off" novalidate="novalidate">
        <div class="grid">
            <div class="field col-12">
                <span class="p-float-label">
                    <input #name="ngModel" type="text" pInputText maxlength="100" name="name" [(ngModel)]="program.name" required />
                    <label>
                        Name
                        <span class="error" *ngIf="name.dirty && name.hasError('required')">
                            Required<i class="fas fa-exclamation"></i>
                        </span>
                    </label>
                </span>
            </div>
            <div class="field col-12">
                <span class="p-float-label">
                    <input pInputText type="text" maxlength="500" name="source" [(ngModel)]="program.source" />
                    <label>Source</label>
                </span>
            </div>
            <div class="col-12">
                <p-dropdown name="daysToCreate" [options]="daysToCreate" [(ngModel)]="program.daysToCreate"></p-dropdown>
            </div>
            <div class="col-12">
                <p-checkbox name="isPublic" [(ngModel)]="program.isPublic" label="Is Public" binary="true"></p-checkbox>
            </div>
            <div class="col-12">
                <button pButton type="submit" label="Create" (click)="create()" [disabled]="isSaving"></button>
                <button pButton label="Cancel" (click)="cancel()" [disabled]="isSaving"></button>
            </div>
        </div>
    </form>
</div>
