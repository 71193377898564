import {
    ModelsCoreWorkoutsPreviousWorkoutDetail,
    ModelsCoreProgramsSetTraversalIndices,
    ModelsCoreProgramsSet,
    ModelsCoreUsersTraineeExercise,
    ModelsCoreWorkoutsWorkoutDetail,
    ModelsCoreWorkoutsWorkoutLog,
    ModelsCoreWorkoutsWorkout
} from '../../shared/swagger-codegen/models';

export class WorkoutEntryState {
    constructor(private workout: ModelsCoreWorkoutsWorkout, private index: number = 0) {
        this.index = this.getStartingIndex(index);
        this.populateState();
    }

    private resting = false;

    private currentIndices: ModelsCoreProgramsSetTraversalIndices;
    private currentWorkoutDetail: ModelsCoreWorkoutsWorkoutDetail;
    private currentWorkoutLog: ModelsCoreWorkoutsWorkoutLog;
    private currentSet: ModelsCoreProgramsSet;
    private previousSessionWorkoutDetail: ModelsCoreWorkoutsPreviousWorkoutDetail;
    private previousIndices: ModelsCoreProgramsSetTraversalIndices;
    private previousWorkoutDetail: ModelsCoreWorkoutsWorkoutDetail;
    private previousWorkoutLog: ModelsCoreWorkoutsWorkoutLog;
    private previousSet: ModelsCoreProgramsSet;
    private nextIndices: ModelsCoreProgramsSetTraversalIndices;
    private nextWorkoutDetail: ModelsCoreWorkoutsWorkoutDetail;
    private nextWorkoutLog: ModelsCoreWorkoutsWorkoutLog;
    private nextSet: ModelsCoreProgramsSet;
    private completedSets: number;
    private workoutProgress: number;

    getIndex() {
        return this.index;
    }

    setIndex(index: number) {
        if(this.index !== index) {
            this.index = this.getStartingIndex(index);
            this.populateState();
        }
    }

    isResting() {
        return this.resting;
    }

    isFirstSet() {
        return this.index === 0;
    }

    isLastSet() {
        const lastIndex = this.workout.setTraversalIndex.length - 1;
        return this.index === lastIndex;
    }

    getCurrentTraversalIndices() {
        return this.currentIndices;
    }

    getCurrentWorkoutDetail() {
        return this.currentWorkoutDetail;
    }

    getCurrentWorkoutLog() {
        return this.currentWorkoutLog;
    }

    getCurrentSet() {
        return this.currentSet;
    }

    getPreviousSessionWorkoutDetail() {
        return this.previousSessionWorkoutDetail;
    }

    getPreviousTraversalIndices() {
        return this.previousIndices;
    }

    getPreviousWorkoutDetail() {
        return this.previousWorkoutDetail;
    }

    getPreviousWorkoutLog() {
        return this.previousWorkoutLog;
    }

    getPreviousSet() {
        return this.previousSet;
    }

    getNextTraversalIndices() {
        return this.nextIndices;
    }

    getNextWorkoutDetail() {
        return this.nextWorkoutDetail;
    }

    getNextWorkoutLog() {
        return this.nextWorkoutLog;
    }

    getNextSet() {
        return this.nextSet;
    }

    getCurrentSetForExercise() {
        return this.currentIndices.setIndex + 1;
    }

    getTotalSetsForExercise() {
        return this.currentWorkoutDetail.sets.length;
    }

    getCurrentSetForWorkout() {
        return this.index + 1;
    }

    getTotalSetsForWorkout() {
        return this.workout.setTraversalIndex.length;
    }

    getWorkoutProgress() {
        return this.workoutProgress;
    }

    getWorkoutProgressDescription() {
        return `Completed ${this.completedSets} of ${this.getTotalSetsForWorkout()} Total Sets`;
    }

    tryPrevious() {
        let changed = false;

        if(this.index > 0) {
            this.index--;
            this.populateState();
            changed = true;
        }

        return changed;
    }

    tryNext() {
        let changed = false;
        const shouldRest = this.currentWorkoutDetail.rest > 0 && !this.resting;

        if(shouldRest) {
            this.resting = true;
        }
        else {
            if(this.index < this.workout.setTraversalIndex.length - 1) {
                this.index++;
                this.populateState();
                changed = true;
            }

            this.resting = false;
        }

        return changed;
    }

    initializeReps() {
        if(this.currentWorkoutLog.reps && this.currentWorkoutLog.reps >= 0) {
            return;
        }

        if(this.previousSetWithSameRepTargetExists()) {
            this.currentWorkoutLog.reps = this.currentWorkoutDetail.workoutLogs[this.currentIndices.setIndex - 1].reps;
        }
        else if(this.currentSet.toFailure) {
            this.currentWorkoutLog.reps = 10;
        }
        else {
            this.currentWorkoutLog.reps = this.currentSet.toReps || this.currentSet.fromReps;
        }
    }

    initializeRepsInReserve() {
        if(this.currentWorkoutLog.repsInReserve && this.currentWorkoutLog.repsInReserve >= 0) {
            return;
        }

        const previousSetIndex = this.currentIndices.setIndex - 1;

        if(previousSetIndex >= 0 && previousSetIndex < this.currentWorkoutDetail.workoutLogs.length) {
            this.currentWorkoutLog.repsInReserve = this.currentWorkoutDetail.workoutLogs[previousSetIndex].repsInReserve;
        }
        else {
            this.currentWorkoutLog.repsInReserve = 3;
        }
    }

    private previousSetWithSameRepTargetExists() {
        let previousSet: ModelsCoreProgramsSet = null;
        const previousSetIndex = this.currentIndices.setIndex - 1;

        if(previousSetIndex >= 0 && previousSetIndex < this.currentWorkoutDetail.sets.length) {
            previousSet = this.currentWorkoutDetail.sets[previousSetIndex];
        }

        return previousSet &&
            previousSet.fromReps === this.currentSet.fromReps &&
            previousSet.toReps === this.currentSet.toReps &&
            previousSet.toFailure === this.currentSet.toFailure;
    }

    initializeWeight() {
        if(this.currentWorkoutLog.weight && this.currentWorkoutLog.weight >= 0) {
            return;
        }

        const previousSetIndex = this.currentIndices.setIndex - 1;
        const traineeExercise = this.currentWorkoutDetail.exercise.traineeExercise
            || {} as ModelsCoreUsersTraineeExercise;

        if(previousSetIndex >= 0 && previousSetIndex < this.currentWorkoutDetail.workoutLogs.length) {
            this.currentWorkoutLog.weight = this.currentWorkoutDetail.workoutLogs[previousSetIndex].weight;
        }
        else if(this.previousSessionWorkoutDetail) {
            this.currentWorkoutLog.weight = this.currentWorkoutDetail.previousWorkoutDetail.earnedProgression
                ? this.previousSessionWorkoutDetail.startingWeight + (traineeExercise.progressionWeight || 5)
                : this.previousSessionWorkoutDetail.startingWeight;
        }
    }

    private populateState() {
        if(!this.workout) {
            return;
        }

        const current = this.selectState();
        const previous = this.selectState(-1);
        const next = this.selectState(1);

        if(current.set) {
            this.currentIndices = current.indices;
            this.currentWorkoutDetail = current.detail;
            this.currentSet = current.set;
            this.previousSessionWorkoutDetail = current.detail.previousWorkoutDetail;

            if(current.workoutLog) {
                this.currentWorkoutLog = current.workoutLog;
            }
            else {
                const log = {} as ModelsCoreWorkoutsWorkoutLog;
                log.workoutDetailId = current.detail.workoutDetailId;
                this.currentWorkoutLog = log;
                current.detail.workoutLogs[current.indices.setIndex] = log;
            }
        }

        this.previousIndices = previous.indices;
        this.previousWorkoutDetail = previous.detail;
        this.previousSet = previous.set;
        this.previousWorkoutLog = previous.workoutLog;

        this.nextIndices = next.indices;
        this.nextWorkoutDetail = next.detail;
        this.nextSet = next.set;
        this.nextWorkoutLog = next.workoutLog;

        this.calculateWorkoutProgress();
    }

    private calculateWorkoutProgress() {
        if(this.workout) {
            let completedSets = 0;

            for (let i = 0; i < this.workout.workoutDetails.length; i++) {
                const detail = this.workout.workoutDetails[i];

                for (let j = 0; j < detail.workoutLogs.length; j++) {
                    const log = detail.workoutLogs[j];

                    if(log && log.reps > 0) {
                        completedSets++;
                    }
                }
            }

            const totalSets = this.getTotalSetsForWorkout();
            this.completedSets = completedSets;
            this.workoutProgress = Math.round((completedSets / totalSets) * 100);
        }
        else {
            this.completedSets = 0;
            this.workoutProgress = 0;
        }
    }

    private selectState(indexAdjustment = 0) {
        this.ensureValidIndex();

        const indices = this.selectCurrentTraversalIndices(indexAdjustment);
        const detail = this.selectCurrentWorkoutDetail(indices);
        const set = this.selectCurrentSet(indices, detail);

        const state = {
            indices: indices,
            detail: detail,
            set: set,
            workoutLog: null as ModelsCoreWorkoutsWorkoutLog
        };

        if(set) {
            if(indices.setIndex >= 0 && indices.setIndex < detail.workoutLogs.length) {
                state.workoutLog = detail.workoutLogs[indices.setIndex];
            }
        }

        return state;
    }

    private ensureValidIndex() {
        const min = 0;
        let max = 0;

        if(this.workout) {
            max = this.workout.setTraversalIndex.length - 1;
        }

        if(this.index < min || this.index > max) {
            this.index = min;
        }
    }

    private selectCurrentTraversalIndices(indexAdjustment = 0) {
        const i = this.index + indexAdjustment;

        if(i >= 0 && i < this.workout.setTraversalIndex.length) {
            return this.workout.setTraversalIndex[i];
        }

        return null;
    }

    private selectCurrentWorkoutDetail(indices: ModelsCoreProgramsSetTraversalIndices) {
        if(indices && indices.detailIndex < this.workout.workoutDetails.length) {
            return this.workout.workoutDetails[indices.detailIndex];
        }

        return null;
    }

    private selectCurrentSet(
        indices: ModelsCoreProgramsSetTraversalIndices,
        detail: ModelsCoreWorkoutsWorkoutDetail) {

        if(indices && detail && indices.setIndex < detail.sets.length) {
            return detail.sets[indices.setIndex];
        }

        return null;
    }

    private getStartingIndex(index: number) {
        let firstCompletableSetIndex = index;

        if(index < 0 || index >= this.workout.setTraversalIndex.length) {
            for(let i = 0; i < this.workout.setTraversalIndex.length; i++) {
                const indices = this.workout.setTraversalIndex[i];
                const detail = this.workout.workoutDetails[indices.detailIndex];

                if(!detail.workoutLogs || detail.workoutLogs.length <= indices.setIndex) {
                    firstCompletableSetIndex = i;
                    break;
                }
            }
        }

        return firstCompletableSetIndex;
    }
}
