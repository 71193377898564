import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { workoutsRoutes } from './workouts.routes';
import { WorkoutEntryComponent } from './entry/workout-entry.component';
import { WorkoutCompletionComponent } from './completion/workout-completion.component';
import { PlateCalculatorComponent } from './plate-calculator/plate-calculator.component';

@NgModule({
    imports: [
        SharedModule,
        workoutsRoutes
    ],
    declarations: [
        WorkoutEntryComponent,
        WorkoutCompletionComponent,
        PlateCalculatorComponent
    ],
    providers: [
    ]
})
export class WorkoutsModule {

}
