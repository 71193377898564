import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';

import { ExerciseDetailDialogComponent } from './exercise-detail-dialog.component';

import {
    ModelsCoreSharedExercise
} from '../../../shared/swagger-codegen/models';

@Injectable()
export class ExerciseDetailDialogService {
    constructor(private dialogService: DialogService) {

    }

    open(exercise: ModelsCoreSharedExercise) {
        return this.dialogService.open(
            ExerciseDetailDialogComponent,
            {
                data: {
                    exercise: exercise
                },
                header: 'Exercise Detail',
                width: '65%',
                height: '65%',
                contentStyle: {
                    width: '100%',
                    height: '100%'
                }
            });
    }
}
