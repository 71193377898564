import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { AuthModule } from './auth/auth.module';
import { CoreModule } from './core/core.module';
import { MessagesModule } from './messages/messages.module';
import { PrimeNgModule } from './primeng/primeng.module';
import { ServerProxiesModule } from './server-proxies/server-proxies.module';

import { httpInterceptorProviders } from './http-interceptors/index';

@NgModule({
    imports: [

    ],
    declarations: [

    ],
    exports: [
        AuthModule,
        CommonModule,
        CoreModule,
        FormsModule,
        MessagesModule,
        PrimeNgModule,
        ServerProxiesModule
    ]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                httpInterceptorProviders
            ]
        };
    }
}
