<form *ngIf="exercise" #exerciseForm="ngForm" autocomplete="off" novalidate="novalidate">
    <div class="grid">
        <div class="field col-12">
            <span class="p-float-label">
                <input id="name" name="name" class="width15" [(ngModel)]="exercise.name" type="text" pInputText maxlength="250" required>
                <label for="name">Name</label>
            </span>
        </div>
        <div class="field col-12">
            <span class="p-float-label">
                <textarea id="notes" name="notes" [(ngModel)]="exercise.notes" type="text" rows="4" pInputTextarea></textarea>
                <label for="notes">Notes</label>
            </span>
        </div>
        <div class="field col-12">
            <span class="p-float-label">
                <input id="videoUrl" name="videoUrl" class="width15" [(ngModel)]="exercise.videoUrl" type="text" pInputText maxlength="200">
                <label for="videoUrl">Video Url</label>
            </span>
        </div>
        <div class="field col-12">
            <span class="p-float-label">
                <p-dropdown inputId="exerciseType" name="exerciseType" class="width6" [(ngModel)]="exercise.exerciseTypeId" [options]="exerciseTypes"></p-dropdown>
                <label for="exerciseType">Type</label>
            </span>
        </div>
        <div class="field col-12">
            <p-checkbox inputId="isWeighted" name="isWeighted" label="Is Weighted" [(ngModel)]="exercise.isWeighted" [binary]="true"></p-checkbox>
        </div>
        <button pButton type="submit" label="Save" (click)="saveExercise()"></button>
    </div>
</form>
