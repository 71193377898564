<div *ngIf="day" class="grid">
    <div class="p-fluid p-formgrid grid">
        <div class="field col-12">
            <label for="name">Name</label>
            <input id="name" [(ngModel)]="day.programDay.description" type="text" pInputText maxlength="100" required>
        </div>

        <div>
            <p-table [value]="day.records" [loading]="isLoading" styleClass="with-bottom-margin">
                <ng-template pTemplate="caption">
                    <div class="center">Sessions</div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th class="center">Name</th>
                        <th class="center width8">Workout Type</th>
                        <th class="center width5">Manually Selected?</th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-record let-programSession="$implicit.programSession" let-rowIndex="rowIndex">
                    <tr>
                        <td>{{getSessionDescription(programSession, rowIndex)}}</td>
                        <td><p-dropdown inputId="workoutType" [(ngModel)]="programSession.workoutTypeId" [options]="workoutTypes"></p-dropdown></td>
                        <td class="center"><p-checkbox name="manuallySelected" [(ngModel)]="programSession.isManuallySelected" binary="true"></p-checkbox></td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
        <button pButton type="button" label="Save Program Day" (click)="saveProgramDay()"></button>
    </div>
</div>
