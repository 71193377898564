import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { ProgramsControllerProxy } from '../../shared/server-proxies/programs-controller-proxy.service';
import { BaseComponentDirective as BaseComponent } from '../../shared/base-component.directive';

import {
    ModelsCoreProgramsPeriodizationPhase,
    ModelsWebApiProgramsCreateProgramModel
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-new-program',
    templateUrl: './new-program.component.html'
})
export class NewProgramComponent extends BaseComponent implements OnInit {
    constructor(
        private programsControllerProxy: ProgramsControllerProxy,
        private router: Router) {
        super();
    }

    program: ModelsWebApiProgramsCreateProgramModel;
    phases: ModelsCoreProgramsPeriodizationPhase[];
    daysToCreate: SelectItem[];
    isSaving: boolean;

    ngOnInit() {
        this.daysToCreate = this.initDaysToCreate();
        this.programsControllerProxy.getPeriodizationPhases()
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(response => {
                this.phases = response.body;
            });

        this.program = {
            daysToCreate: 4
        } as ModelsWebApiProgramsCreateProgramModel;
    }

    create() {
        this.programsControllerProxy.createProgram(this.program)
            .pipe(this.takeUntilUnsubscribed())
            .subscribe(response => {
                const newProgramId = response.body.programId;
                this.router.navigate(['/programs', newProgramId]);
            });
    }

    cancel() {
        this.router.navigate(['/dashboard']);
    }

    private initDaysToCreate() {
        const days: SelectItem[] = [];

        for (let i = 1; i < 15; i++) {
            days.push({ label: i.toString(), value: i });
        }

        return days;
    }
}
