<div class="grid">
    <div class="col-12">
        <div class="card card-w-title">
            <h1>Dashboard</h1>
            <div class="grid">
                <div class="col-12 lg:col-5">
                    <p-table [value]="programs" [paginator]="programs && programs.length > 5" [pageLinks]="5" [rows]="5" [loading]="isLoading">
                        <ng-template pTemplate="caption">
                            Active Programs
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th>Program</th>
                                <th class="width2 center">First<br />Workout</th>
                                <th class="width2 center">Last<br />Workout</th>
                                <th class="width2 center">Workouts<br />Completed</th>
                                <th class="width6"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-program let-rowIndex="rowIndex">
                            <tr>
                                <td>
                                    <span class="p-column-title">Program</span>
                                    <a routerLink="/programs/{{program.programId}}">{{program.name}}</a>
                                </td>
                                <td class="center">
                                    <span class="p-column-title">First Workout</span>
                                    {{program.firstWorkoutCompleted | date:'shortDate'}}
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Last Workout</span>
                                    {{program.lastWorkoutCompleted | date:'shortDate'}}
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Workouts Completed</span>
                                    {{program.workoutsCompleted | number}}
                                </td>
                                <td class="center">
                                    <p-splitButton label="New Workout" icon="far fa-play-circle" (onClick)="startWorkout(program)" [model]="getNewWorkoutMenuItems(program)" [disabled]="isSaving"></p-splitButton>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="3">
                                    No active programs.
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="col-12 lg:col-7">
                    <p-table [value]="workouts" [paginator]="workouts && workouts.length > 15" [pageLinks]="5" [rows]="15" [loading]="isLoading">
                        <ng-template pTemplate="caption">
                            Recent Workouts
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th rowspan="2">Program</th>
                                <th rowspan="2">Workout</th>
                                <th colspan="3" class="center">Sets</th>
                                <th rowspan="2" class="width6 center">Completed</th>
                                <th rowspan="2" class="width1 center">Duration</th>
                            </tr>
                            <tr>
                                <th class="width1 center">Prescribed</th>
                                <th class="width1 center">Completed</th>
                                <th class="width1 center">Skipped</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-workout let-rowIndex="rowIndex">
                            <tr [ngClass]="getWorkoutRowCssClass(rowIndex)">
                                <td>
                                    <span class="p-column-title">Program</span>
                                    {{workout.program.name}}
                                </td>
                                <td>
                                    <span class="p-column-title">Workout</span>
                                    <a routerLink="/workouts/{{workout.workoutId}}">{{workout.name}}</a>
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Sets Prescribed</span>
                                    {{workout.prescribedSets | number}}
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Sets Completed</span>
                                    {{workout.completedSets | number}}
                                </td>
                                <td class="center" [ngClass]="getSkippedSetsCssClass(workout)">
                                    <span class="p-column-title">Sets Skipped</span>
                                    {{workout.skippedSets | number}}
                                </td>
                                <td class="right">
                                    <span class="p-column-title">Completed</span>
                                    {{workout.completed | date:'EEE, M/d/yy'}}
                                    {{workout.completed | date:'shortTime'}}
                                    <span class="status-icons" *ngIf="!workout.completed">
                                        <br />
                                        <i pRipple class="fas fa-trash-alt clickable delete-icon" pTooltip="Delete Workout" (click)="confirmDeleteWorkout(workout, rowIndex)"></i>
                                        <i pRipple class="fas fa-stop-circle clickable cancel-icon" pTooltip="End Workout" (click)="confirmCompleteWorkout(workout)"></i>
                                    </span>
                                </td>
                                <td class="center">
                                    <span class="p-column-title">Duration</span>
                                    {{workout.elapsedDuration}}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td colspan="7">
                                    No workouts.
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
    </div>
</div>
<p-confirmDialog key="dashboard-component" icon="pi pi-exclamation-triangle"></p-confirmDialog>
