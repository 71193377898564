import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { SessionService } from '../../shared/auth/session.service';
import { ProgramsControllerProxy } from '../../shared/server-proxies/programs-controller-proxy.service';
import { WorkoutsControllerProxy } from '../../shared/server-proxies/workouts-controller-proxy.service';
import { BaseComponentDirective as BaseComponent } from '../../shared/base-component.directive';
import { finalize } from 'rxjs/operators';

import {
    ModelsCoreUsersUserAccount,
    ModelsCoreProgramsProgram,
    ModelsCoreWorkoutsWorkout,
    ModelsWebApiWorkoutsGetWorkoutsArgs,
    ModelsWebApiProgramsStartNextWorkoutArgs
} from '../../shared/swagger-codegen/models';

@Component({
    selector: 'my-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: [
        './dashboard.component.scss'
    ]
})
export class DashboardComponent extends BaseComponent implements OnInit {
    constructor(
        private router: Router,
        private session: SessionService,
        private confirmationService: ConfirmationService,
        private programsControllerProxy: ProgramsControllerProxy,
        private workoutsControllerProxy: WorkoutsControllerProxy) {
        super();
    }

    isLoading = false;
    isSaving = false;
    user: ModelsCoreUsersUserAccount;
    programs: ModelsCoreProgramsProgram[] = [];
    workouts: ModelsCoreWorkoutsWorkout[] = [];
    private workoutWeekNumbers: number[] = [];

    ngOnInit() {
        this.user = this.session.getUser();
        this.isLoading = true;
        this.programsControllerProxy.getPrograms()
            .pipe(
                finalize(
                    () => {
                        this.isLoading = false;
                    }),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.programs = response.body;
                });

        const args: ModelsWebApiWorkoutsGetWorkoutsArgs = {
            page: 0,
            pageSize: 40
        };

        this.workoutsControllerProxy.getWorkouts(args)
            .pipe(
                finalize(
                    () => {
                        this.isLoading = false;
                    }),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.workouts = response.body;
                    this.populateWorkoutWeekNumbers();
                });
    }

    startWorkout(program: ModelsCoreProgramsProgram, programSessionId?: number) {
        this.isSaving = true;

        const args: ModelsWebApiProgramsStartNextWorkoutArgs = {
            programSessionId: programSessionId
        };

        this.programsControllerProxy.startNextWorkout(program.programId, args)
            .pipe(
                finalize(
                    () => {
                        this.isSaving = false;
                    }),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    this.router.navigate(['/workouts', response.body.workoutId]);
                });
    }

    getNewWorkoutMenuItems(program: ModelsCoreProgramsProgram) {
        const items: MenuItem[] = [];

        program.programSessionSpecifiers.forEach(s => {
            items.push({
                label: s.description,
                command: () => this.startWorkout(program, s.programSessionId)
            });
        });

        return items;
    }

    confirmCompleteWorkout(workout: ModelsCoreWorkoutsWorkout) {
        this.confirmationService.confirm(
            {
                key: 'dashboard-component',
                header: 'End Workout?',
                message: 'Are you sure you want to end this workout?',
                accept: () => {
                    this.completeWorkout(workout);
                }
            });
    }

    confirmDeleteWorkout(workout: ModelsCoreWorkoutsWorkout, rowIndex: number) {
        this.confirmationService.confirm(
            {
                key: 'dashboard-component',
                header: 'Delete Workout?',
                message: 'Are you sure you want to delete this workout?',
                accept: () => {
                    this.deleteWorkout(workout, rowIndex);
                }
            });
    }

    getWorkoutRowCssClass(rowIndex: number) {
        const css = {};

        if(rowIndex > 0 && rowIndex < this.workoutWeekNumbers.length) {
            const previousWeekNumber = this.workoutWeekNumbers[rowIndex - 1];
            const currentWeekNumber = this.workoutWeekNumbers[rowIndex];

            if(previousWeekNumber !== currentWeekNumber) {
                css['week-boundary'] = true;
            }
        }

        return css;
    }

    getSkippedSetsCssClass(workout: ModelsCoreWorkoutsWorkout) {
        const css = {};

        if(workout.skippedSets > 12) {
            css['skipped-sets-4'] = true;
        }
        else if(workout.skippedSets > 9) {
            css['skipped-sets-3'] = true;
        }
        else if(workout.skippedSets > 6) {
            css['skipped-sets-2'] = true;
        }
        else if(workout.skippedSets > 0) {
            css['skipped-sets-1'] = true;
        }
        else {
            css['skipped-sets-0'] = true;
        }

        return css;
    }

    private completeWorkout(workout: ModelsCoreWorkoutsWorkout) {
        this.isSaving = true;
        this.workoutsControllerProxy.completeWorkout(workout.workoutId)
            .pipe(
                finalize(
                    () => {
                        this.isSaving = false;
                    }),
                this.takeUntilUnsubscribed())
            .subscribe(
                response => {
                    const result = response.body;
                    workout.completed = result.completed;
                    workout.completedSets = result.completedSets;
                    workout.skippedSets = result.skippedSets;
                });
    }

    private deleteWorkout(workout: ModelsCoreWorkoutsWorkout, rowIndex: number) {
        this.isSaving = true;
        this.workoutsControllerProxy.deleteWorkout(workout.workoutId)
            .pipe(
                finalize(
                    () => {
                        this.isSaving = false;
                    }),
                this.takeUntilUnsubscribed())
            .subscribe(
                () => {
                    this.workouts.splice(rowIndex, 1);
                });
    }

    private populateWorkoutWeekNumbers() {
        const weekNumbers: number[] = [];

        this.workouts.forEach(w => {
            const date = new Date(w.started ?? w.created);
            weekNumbers.push(date.getWeekNumber());
        });

        this.workoutWeekNumbers = weekNumbers;
    }
}
